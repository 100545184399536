
















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import NetworkIntegrationResponseModel, {
  AuthorizationType,
  NetworkType,
} from "@/settings/models/networks-management/NetworkIntegrationResponseModel";
import NetworkUrlResponseModel from "@/settings/models/networks-management/NetworkUrlResponseModel";
import { RequestType } from "@/settings/models/networks-management/NetworkUrlRequestModel";
import AccountGroupResponseModel from "@/settings/models/networks-management/AccountGroupResponseModel";

@Component
export default class NetworkIntegrationMenu extends Vue {
  @Prop() networkIntegration!: NetworkIntegrationResponseModel;

  isActiveListGroup = false;

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get accountsGroups(): Array<AccountGroupResponseModel> {
    return this.$store.state.networksManagementStore.accountsGroups;
  }

  get accountsGroupsMenuItems(): Array<Record<string, any>> {
    return [
      {
        items: [
          {
            title: this.$lang("appAdmin.networksManagement.list"),
            route: {
              name: "accountsGroups",
            },
          },
          ...this.accountsGroups.map(({ accountGroupName, id }) => ({
            title: accountGroupName,
            route: { name: "accountGroup", params: { accountGroupId: id } },
          })),
        ],
        title: this.$lang("appAdmin.networksManagement.accountsGroupsTitle"),
      },
    ];
  }

  get singleMenuItems(): Array<Record<string, any>> {
    return [
      ...(this.networkIntegration.authorizationType ===
      AuthorizationType.PRE_AUTH_REQUEST
        ? [
            {
              title: this.$lang("appAdmin.networksManagement.authentication"),
              route: {
                name: "authentication",
              },
            },
          ]
        : []),
      ...(this.customDataRequests.length
        ? [
            {
              title: this.$lang(
                "appAdmin.networksManagement.customDataRequests"
              ),
              route: {
                name: "customDataRequests",
              },
            },
          ]
        : [
            {
              title: this.$lang("appAdmin.networksManagement.dataRequest"),
              route: {
                name: "dataRequest",
              },
            },
          ]),
      {
        title: this.$lang("appAdmin.networksManagement.parsingRules.title"),
        route: {
          name: "parsingRules",
        },
      },
      ...(this.networkIntegration.networkType === NetworkType.REVENUE
        ? [
            {
              title: this.$lang("appAdmin.networksManagement.netRevenue.title"),
              route: {
                name: "netRevenue",
              },
            },
          ]
        : []),
      ...(this.networkIntegration.networkType === NetworkType.REVENUE &&
      this.networkIntegration.hasChildEntities
        ? [
            {
              title: this.$lang(
                "appAdmin.networksManagement.childNetworks.title"
              ),
              route: {
                name: "childNetworks",
              },
            },
          ]
        : []),
    ];
  }

  get customDataRequests(): Array<NetworkUrlResponseModel> {
    return this.$store.state.networksManagementStore.networkRequests.filter(
      ({ requestType }: { requestType: RequestType }) =>
        ![
          RequestType.DATA_FETCH,
          RequestType.AUTH,
          RequestType.PERSISTENT_AUTH,
        ].includes(requestType)
    );
  }

  @Watch("$route", { deep: true, immediate: true })
  watchRoute() {
    this.isActiveListGroup =
      this.$route.name === "accountsGroups" ||
      this.$route.name === "accountGroup";
  }
}
