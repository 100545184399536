var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pa-3",style:(("border-bottom: 1px solid " + (_vm.dark ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)')))},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"auto"}},[_c('span',{staticClass:"text-h6 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$lang( ("appAdmin.networksManagement.networkIntegration." + (_vm.networkIntegration.networkType.toLowerCase())) ))+" ")]),_c('v-chip',{staticClass:"ml-3",attrs:{"color":_vm.isDisableStatus ? 'error' : 'success',"outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.$lang( ("appAdmin.networksManagement." + (_vm.isDisableStatus ? "disabled" : "activated")) ))+" ")])],1)],1),_c('v-row',{staticClass:"mt-0"},[(_vm.networkIntegration.hasParentNetwork)?_c('v-col',{staticClass:"pt-2",attrs:{"cols":"auto"}},[_c('span',{class:[
              'text-subtitle-1',
              'font-weight-bold',
              _vm.dark ? 'white--text' : 'black--text' ]},[_vm._v(" "+_vm._s(_vm.$lang("appAdmin.networksManagement.parentNetwork"))+": ")]),_c('router-link',{attrs:{"to":{
              name: 'networkIntegration',
              params: {
                id: _vm.applicationId,
                networkId: String(_vm.networkIntegration.parentNetworkId),
                networkIntegrationId: String(
                  _vm.networkIntegration.parentNetworkIntegrationId
                ),
              },
            },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.networkIntegration.parentNetworkName)+" ")])],1):_vm._e(),_c('v-col',{staticClass:"pt-2",attrs:{"cols":"auto"}},[_c('span',{class:[
              'text-subtitle-1',
              'font-weight-bold',
              _vm.dark ? 'white--text' : 'black--text' ]},[_vm._v(" "+_vm._s(_vm.$lang("appAdmin.networksManagement.authorizationType"))+": ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$lang( ("appAdmin.networksManagement." + (_vm.networkIntegration.authorizationType.toLowerCase())) ))+" ")])]),_c('v-col',{staticClass:"pt-2",attrs:{"cols":"auto"}},[_c('span',{class:[
              'text-subtitle-1',
              'font-weight-bold',
              _vm.dark ? 'white--text' : 'black--text' ]},[_vm._v(" "+_vm._s(_vm.$lang( "appAdmin.networksManagement.daysAgo", _vm.networkIntegration.deltaFrom, _vm.networkIntegration.deltaTo ))+": ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$lang( "appAdmin.networksManagement.fromTo", _vm.networkIntegration.deltaFrom, _vm.networkIntegration.deltaTo ))+" ")])]),(_vm.lastExecutionStatus)?_c('v-col',{staticClass:"pt-2",attrs:{"cols":"auto"}},[_c('span',{class:[
              'text-subtitle-1',
              'font-weight-bold',
              _vm.dark ? 'white--text' : 'black--text' ]},[_vm._v(" "+_vm._s(_vm.$lang("appAdmin.networksManagement.lastReceivedStatistics"))+": ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"ml-1",attrs:{"color":_vm.statisticsStatusColor,"small":""}},on),[_vm._v(" "+_vm._s(_vm.lastExecutionStatus.slice(0, 1) + _vm.lastExecutionStatus.slice(1).toLowerCase())+" ")])]}}],null,false,2161350669)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.statisticsTooltipText)}})])],1):_vm._e()],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.menuItems),function(item){return _c('v-list-item',{key:item.label,class:item.color,attrs:{"link":"","to":item.route || null},on:{"click":function($event){item.function() || null}}},[_c('v-list-item-icon',[_c('v-icon',{class:item.color},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(item.label)+" ")])],1)}),1)],1)],1)],1),_c('network-sidebar-form',{attrs:{"title":_vm.$lang('appAdmin.networksManagement.editIntegration'),"saving-in-progress":_vm.savingIntegrationInProgress},on:{"save":_vm.emitUpdate},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('network-integration-form',{attrs:{"is-edit":true},model:{value:(_vm.localValueIntegration),callback:function ($$v) {_vm.localValueIntegration=$$v},expression:"localValueIntegration"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }