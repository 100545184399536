



































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import NetworkSidebarForm from "./NetworkSidebarForm.vue";
import NetworkIntegrationForm from "./NetworkIntegrationForm.vue";
import NetworkIntegrationRequestModel from "@/settings/models/networks-management/NetworkIntegrationRequestModel";
import NetworkIntegrationResponseModel, {
  NetworkIntegrationStatus,
  NetworkType,
} from "@/settings/models/networks-management/NetworkIntegrationResponseModel";
import { StatisticExecutionStatus } from "@/settings/models/networks-management/AccountGroupStatisticModel";
import DateUtil from "@/shared/utils/DateUtil";
import { NetworkIntegrationFilterType } from "@/settings/models/networks-management/NetworkIntegrationFilterType";

@Component({
  components: {
    NetworkSidebarForm,
    NetworkIntegrationForm,
  },
})
export default class NetworkIntegrationInfo extends Vue {
  @Prop() networkIntegration!: NetworkIntegrationResponseModel;

  visible = false;
  localValueIntegration = new NetworkIntegrationRequestModel();

  get applicationId(): string {
    return this.$store.state.application.applicationId;
  }

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get isDisableStatus(): boolean {
    return this.networkIntegration.status === NetworkIntegrationStatus.DISABLED;
  }

  get lastExecutionStatus(): StatisticExecutionStatus | null {
    return (
      (this.networkIntegration
        ?.lastExecutionStatus as StatisticExecutionStatus) || null
    );
  }

  get lastExecutionEndDate(): string | Date | null {
    return this.networkIntegration?.lastExecutionEndDate || null;
  }

  get lastExecutionMessage(): string | null {
    return this.networkIntegration?.lastExecutionMessage || null;
  }

  get statisticsStatusColor(): string {
    if (!this.lastExecutionStatus) {
      return "";
    }

    return {
      [StatisticExecutionStatus.FAILED]: "error",
      [StatisticExecutionStatus.SUCCESS]: "success",
    }[this.lastExecutionStatus];
  }

  get statisticsTooltipText(): string {
    return `
      <b>${this.$lang(
        "appAdmin.networksManagement.statistics.executionEndDate"
      )}:</b> ${DateUtil.formatDateTime(this.lastExecutionEndDate)}
      ${
        this.lastExecutionStatus === StatisticExecutionStatus.SUCCESS
          ? ""
          : `<br><b>${this.$lang(
              "appAdmin.networksManagement.statistics.message"
            )}:</b> ${this.lastExecutionMessage}`
      }
    `;
  }

  get menuItems(): Array<Record<string, any>> {
    return [
      {
        function: this.handleEditingNetworkIntegration,
        label: this.$lang("btn.edit"),
        icon: "mdi-pencil",
      },
      {
        function: this.emitToggleStatus,
        label: this.$lang(
          `btn.${this.isDisableStatus ? "activate" : "disable"}`
        ),
        icon: this.isDisableStatus ? "mdi-play-circle" : "mdi-stop-circle",
      },
      {
        function: this.emitResync,
        label: this.$lang("btn.resync"),
        icon: "mdi-reload",
      },
      {
        function: this.emitDelete,
        label: this.$lang("btn.delete"),
        icon: "mdi-delete",
        color: "error--text",
      },
    ];
  }

  get savingIntegrationInProgress(): boolean {
    return this.$store.state.networksManagementStore
      .savingIntegrationInProgress;
  }

  @Watch("visible")
  watchVisible(value: boolean) {
    if (value) {
      this.$store.dispatch("loadShortNetworkIntegrations", {
        networkType: NetworkType.REVENUE,
        filter: NetworkIntegrationFilterType.WITHOUT_CHILDREN,
      });
    }
  }

  handleEditingNetworkIntegration() {
    this.visible = true;
    this.localValueIntegration = NetworkIntegrationRequestModel.ofSource(
      this.networkIntegration
    );
  }

  emitToggleStatus() {
    this.$emit(
      "toggleStatus",
      this.isDisableStatus
        ? NetworkIntegrationStatus.ACTIVE
        : NetworkIntegrationStatus.DISABLED
    );
  }

  emitResync() {
    this.$emit("resync", this.networkIntegration.id);
  }

  emitDelete() {
    this.$emit("delete", this.networkIntegration.id);
  }

  emitUpdate(closeDialog: () => void) {
    this.$emit("update", { payload: this.localValueIntegration, closeDialog });
  }
}
