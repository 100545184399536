




























import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import NetworkIntegrationInfo from "@/settings/components/networks-management/NetworkIntegrationInfo.vue";
import NetworkIntegrationMenu from "@/settings/components/networks-management/NetworkIntegrationMenu.vue";
import NetworkIntegrationResponseModel, {
  NetworkIntegrationStatus,
} from "@/settings/models/networks-management/NetworkIntegrationResponseModel";
import NetworkIntegrationRequestModel from "@/settings/models/networks-management/NetworkIntegrationRequestModel";
import { AppSection } from "@/shared/models";

@Component({
  components: {
    NetworkIntegrationInfo,
    NetworkIntegrationMenu,
  },
})
export default class NetworkIntegrationView extends Vue {
  @Prop() networkIntegration!: NetworkIntegrationResponseModel;

  get networkIntegrations(): Array<NetworkIntegrationResponseModel> {
    return this.$store.state.networksManagementStore.networkIntegrations;
  }

  @Watch("networkIntegration", { immediate: true, deep: true })
  watchNetworkIntegration() {
    this.$store.commit("clearAllInformationForNetworkIntegration");

    Promise.all([
      this.$store.dispatch(
        "loadNetworkRequestsByNetworkIntegrationId",
        this.networkIntegration.id
      ),
      this.$store.dispatch(
        "loadAccountsGroupsByNetworkIntegrationId",
        this.networkIntegration.id
      ),
    ]);
  }

  destroyed() {
    this.$store.commit("clearAllInformationForNetworkIntegration");
  }

  handleResyncNetworkIntegration(id: number) {
    this.$store.dispatch("resyncNetworkIntegration", id);
  }

  async handleDeletingNetworkIntegration(id: number) {
    await this.$store.dispatch("deleteIntegration", id);

    if (this.networkIntegrations.length) {
      this.$router.push({
        name: "networkIntegration",
        params: {
          networkIntegrationId: String(this.networkIntegrations[0].id),
        },
      });
    } else {
      this.$router.push({
        name: AppSection.NETWORKS_MANAGEMENT,
      });
    }
  }

  async handleUpdatingNetworkIntegration({
    payload,
    closeDialog,
  }: {
    payload: NetworkIntegrationRequestModel;
    closeDialog: () => void;
  }) {
    await this.$store.dispatch("updateIntegration", {
      id: this.networkIntegration.id,
      payload,
    });

    closeDialog();
  }

  toggleNetworkIntegrationStatus(status: NetworkIntegrationStatus) {
    this.$store.dispatch("updateIntegration", {
      id: this.networkIntegration.id,
      payload: {
        ...NetworkIntegrationRequestModel.ofSource(this.networkIntegration),
        status,
      },
    });
  }
}
